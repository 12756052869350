<template>
    <div class="action-open-chat">
        <div v-if="validSubscription && chat.agent_id !== 3">
            <ActionOpenChatMembership :message="message" :item="validSubscription" />
        </div>
        <div v-else-if="chat.agent_id === 1 || chat.agent_id === 2">
            <ActionOpenChatMatching :message="message" />
        </div>
        <div v-else-if="chat.agent_id === 7">
            <ActionOpenChatSupport :message="message" />
        </div>
        <div v-else-if="chat.agent_id === 8">
            <!-- agent id 임의값-->
            <ActionOpenChatJudgeManager :message="message" />
        </div>
        <div v-else>
            <ActionOpenChatNormal :message="message" v-if="message.$$friendType === 'normal'" />
            <ActionOpenChatInstant v-else-if="message.$$friendType !== 'normal'" :message="message" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChat',
    props: ['message'],
    components: {
        ActionOpenChatNormal: () => import('./ActionOpenChatNormal'),
        ActionOpenChatMatching: () => import('./ActionOpenChatMatching'),
        ActionOpenChatMembership: () => import('./ActionOpenChatMembership'),
        ActionOpenChatInstant: () => import('./ActionOpenChatInstant'),
        // ActionOpenChatAI: () => import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatAI'),
        ActionOpenChatSupport: () =>
            import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatSupport'),
        ActionOpenChatJudgeManager: () =>
            import('@/routes/chat/chatroom/components/message/message-actions/ActionOpenChatJudgeManager'),
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        me() {
            return this.$store.getters.me
        },
        enabledProduct() {
            const products = this.me.products
            return products.some(item => item.enabled)
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || false
        },
        validSubscription() {
            const plan = this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan' && o.product_id >= 220)
            return plan
        },
    },
}
</script>
